var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"width":325,"app":"","clipped":"","color":"element"}},_vm._l((Object.entries(_vm.steps)),function(ref,index){
var key = ref[0];
var value = ref[1];
return _c('div',{key:index},[(key !== 'classification' || (_vm.showInvestmentClassification && _vm.mainStage !== 'investmentSurvey'))?_c('v-list-group',{attrs:{"value":_vm.$route.name === ("Onboarding." + (key.capitalize())),"disabled":"","no-action":"","append-icon":null},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{class:_vm.isCompleted(key, _vm.mainStage, Object.keys(_vm.steps)) ? 'success--text' : null},[_c('v-icon',{staticClass:"ml-3 mb-1 mr-3",attrs:{"color":_vm.isCompleted(key, _vm.mainStage, Object.keys(_vm.steps))
                ? 'success'
                : _vm.$route.name === ("Onboarding." + (key.capitalize()))
                ? 'primary'
                : null}},[_vm._v("\n                "+_vm._s(_vm.icons[key])+"\n            ")]),_vm._v("\n            "+_vm._s(_vm.$t(("onboarding.sections." + key + ".title")))+"\n          ")],1)],1)]},proxy:true}],null,true)},[_vm._v(" "),_vm._l((value),function(step,i){return _c('v-list-item',{key:i,staticClass:"m-5",attrs:{"link":"","disabled":step !== _vm.$route.params.step,"to":("/onboarding/" + key + "/" + step)}},[_c('v-list-item-content',[_c('v-list-item-title',{class:_vm.isCompleted(step, _vm.subStage, value) && _vm.editMode !== step ? 'success--text' : null},[(_vm.editMode === step ? false : _vm.isCompleted(step, _vm.subStage, value))?_c('v-icon',{staticClass:"mb-1",attrs:{"color":"success"}},[_vm._v("\n              mdi-check-bold\n            ")]):_vm._e(),_vm._v("\n            "+_vm._s(_vm.$t(("onboarding.sections." + key + ".steps." + step)))+"\n          ")],1)],1)],1)})],2):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }