<template>
  <v-navigation-drawer
    :width="325"
    app
    clipped
    color="element"
  >
    <div
      v-for="([key, value], index) in Object.entries(steps)"
      :key="index"
    >
      <v-list-group
        v-if="key !== 'classification' || (showInvestmentClassification && mainStage !== 'investmentSurvey')"
        :value="$route.name === `Onboarding.${key.capitalize()}`"
        disabled
        no-action
        :append-icon="null"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title
            :class="isCompleted(key, mainStage, Object.keys(steps)) ? 'success--text' : null"
            >
              <v-icon
                :color="isCompleted(key, mainStage, Object.keys(steps))
                  ? 'success'
                  : $route.name === `Onboarding.${key.capitalize()}`
                  ? 'primary'
                  : null
                "
                class="ml-3 mb-1 mr-3"
              >
                  {{ icons[key] }}
              </v-icon>
              {{ $t(`onboarding.sections.${key}.title`) }}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="(step, i) in value"
          :key="i"
          class="m-5"
          link
          :disabled="step !== $route.params.step"
          :to="`/onboarding/${key}/${step}`"
        >
          <v-list-item-content>
            <v-list-item-title
              :class="isCompleted(step, subStage, value) && editMode !== step ? 'success--text' : null"
            >
              <v-icon
                v-if="editMode === step ? false : isCompleted(step, subStage, value)"
                color="success"
                class="mb-1"
              >
                mdi-check-bold
              </v-icon>
              {{ $t(`onboarding.sections.${key}.steps.${step}`) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OnboardingSideMenu',
  props: {
    steps: {
      required: true,
    },
    icons: {
      required: true,
    },
    mainStage: {
      required: true,
    },
    subStage: {
      required: true,
    },
    editMode: {
      default: null,
    },
  },
  computed: {
    ...mapGetters('user', [
      'investmentSurvey',
      'showInvestmentClassification',
    ]),
  },
  methods: {
    isCompleted(item, stage, steps) {
      const itemIndex = steps.findIndex((step) => step === item);
      const stageIndex = steps.findIndex((step) => step === stage);
      return itemIndex < stageIndex;
    },
  },
};
</script>
